/*!
 * SpreadNShare 3.0 (http://www.virtualys.com)
 * Copyright 2008-2015 Virtualys / IFREMER
 */
.c_menu.l_core_collapsiblesubmenu .menupane > div > .row {
  padding: 20px 0;
}
.c_menu.l_core_collapsiblesubmenu .menupane > div > .row > div:first-child {
  padding: 0 40px;
}
.c_menu.l_core_collapsiblesubmenu .menupane > div > .row > div > .row > div:first-child {
  padding-left: 8px;
}
.c_menu.l_core_collapsiblesubmenu .menupane .row > div {
  padding: 0 15px;
}
.c_menu.l_core_collapsiblesubmenu .menupane .row > div + div {
  border-left: 1px solid #e2ebf2;
}
.c_menu.l_core_collapsiblesubmenu .menupane a {
  display: block;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #004f9e;
}
.c_menu.l_core_collapsiblesubmenu .menupane a:hover {
  color: #01bbf4;
}
.c_menu.l_core_collapsiblesubmenu .menupane li li a {
  padding: 0 0 0 1.5em;
}
.c_menu.l_core_collapsiblesubmenu .menupane .loading span {
  color: #004f9e;
}
.c_menu.l_core_collapsiblesubmenu .menupane .thumbnail {
  margin: 0;
}
.c_menu.l_core_collapsiblesubmenu .menupane .thumbnail a {
  padding: 0;
}
.c_menu.l_core_collapsiblesubmenu .menupane .thumbnail figure span {
  display: none;
}
.c_menu.l_core_collapsiblesubmenu .menupane .thumbnail.box {
  background: #eef3f7;
}
.c_menu.l_core_collapsiblesubmenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.c_menu.l_core_collapsiblesubmenu li {
  font-size: 1.1em;
  line-height: 1.1;
}
.c_menu.l_core_collapsiblesubmenu li + li {
  margin-top: 15px;
}
.c_menu.l_core_collapsiblesubmenu li li {
  font-size: .875em;
  line-height: 1.6;
  margin: 0;
}
.c_menu.l_core_collapsiblesubmenu li li + li {
  margin-top: 0;
}
.c_menu.l_core_collapsiblesubmenu .current > a {
  color: #01bbf4;
}
#header_mobilemenupanel {
  margin-top: 10px;
  padding-top: 10px;
  overflow-y: auto;
  border-top: 1px solid #e2ebf2;
  max-height: 60vh;
}
/*# sourceMappingURL=cpnt_snshtml5_menu.collapsiblesubmenu-theme.css.map */